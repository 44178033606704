import { AppService } from './../../../app.service';
import { environment } from './../../../../environments/environment';
import { AlertService } from './../alert.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { PagerService } from '../../services/Pager/pager';
import { LoginToContinueComponent } from '../login-to-continue/login-to-continue.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info-atrelamento',
  templateUrl: './info-atrelamento.component.html',
  styleUrls: ['./info-atrelamento.component.scss']
})
export class InfoAtrelamentoComponent implements OnInit {

  displayType = '';
  videos = [];
  tutoriais = [];
  fotos = [];
  diagramas = [];
  pager = null;
  user = null;
  slideIndex = 0;
  zoomPdf = 0;
  numPagPdf = 0;
  pageIndex = 1;
  loading = false;
  panning = false;
  startPoint = {
    x: 0,
    y: 0,
  }
  filterDiagram = {
    pag: 1,
    totalPag: 0,
    totalPagVet: []
  }

  constructor(
    private dialog: MatDialogRef<InfoAtrelamentoComponent>,
    private alertService: AlertService,
    private pagerService: PagerService,
    private appService: AppService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: Data_Interface
  ) { }

  async ngOnInit() {
    this.user = await this.appService.getUser().value;
    addEventListener('mousemove', (event) => {
      if (this.panning) {
        const elem: any = document.getElementsByClassName('ng2-pdf-viewer-container')[0];
        // offsetWidth
        // scrollLeft
        if (Math.sign(event.screenX - this.startPoint.x) == -1) {
          if (((Math.abs(event.screenX - this.startPoint.x) / this.startPoint.x) * 100) <= 100) {
            console.log((elem.offsetWidth * (Math.abs(event.screenX - this.startPoint.x) / this.startPoint.x)))
            elem.scrollLeft = (elem.offsetWidth * (Math.abs(event.screenX - this.startPoint.x) / this.startPoint.x))
          }
        } else {
          if (((Math.abs(event.screenX - this.startPoint.x) / this.startPoint.x) * 100) <= 100) {
            console.log(elem.offsetWidth - (elem.offsetWidth * (Math.abs(event.screenX - this.startPoint.x) / this.startPoint.x)))
            elem.scrollLeft = (elem.offsetWidth - (elem.offsetWidth * (Math.abs(event.screenX - this.startPoint.x) / this.startPoint.x)))
          }
        }
      }
    });
    addEventListener('mouseup', (event) => {
      this.panning = false;
      this.startPoint.x = 0
      this.startPoint.y = 0
    });
    addEventListener('mousedown', (event) => {
      this.panning = true;
      this.startPoint.x = event.screenX
      this.startPoint.y = event.screenY
    });
    switch (this.data.type) {
      case 'forum':
        this.getForum();
        this.displayType = 'Fórums'
        break;
      case 'diagrama':
        this.getDiagramaFilter();
        this.displayType = 'Diagramas'
        break;
      case 'tutorial':
        this.getTutorial();
        this.displayType = 'Tutoriais'
        break;
      case 'image':
        this.getImage();
        this.displayType = 'Imagens'
        break;
      case 'video':
        this.videos = this.data.id;
        this.displayType = 'Vídeos'
        break;

      default:
        break;
    }
  }

  close() {
    this.dialog.close();
  }

  getForum() {

  }

  getImage() {
    this.data.id.forEach(id => {
      try {
        this.alertService.getImg(id).subscribe((res: any) => {
          //Sucesso
          var binary = '';
          var bytes = new Uint8Array(res);
          var len = bytes.byteLength;
          for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          this.fotos.push({ data: 'data:image/jpg;base64,' + window.btoa(binary), id: id });
          this.loading = false;
        },
          (error) => {
            //Falha
            this.loading = false;
          });
      } catch (error) {
        //Falha
        this.loading = false;
      }
    });
  }

  getDiagramaFilter() {
    this.loading = true;
    this.filterDiagram.totalPagVet = [];
    this.filterDiagram.totalPag = Math.ceil(this.data.id.length / 5);
    for (let index = 0; index < this.filterDiagram.totalPag; index++) {
      this.filterDiagram.totalPagVet.push(index + 1);
    }
    this.pager = this.pagerService.getPager(this.filterDiagram.totalPag, this.filterDiagram.pag, 10);
    const idDiagramas = this.data.id;
    const newIdDiagramas = idDiagramas.slice((this.filterDiagram.pag - 1) * 5, this.filterDiagram.pag * 5);
    this.getDiagrama(newIdDiagramas);
  }

  getDiagrama(IdDiagramas) {
    this.diagramas = [];
    console.log(IdDiagramas)
    IdDiagramas.forEach(id => {
      try {
        this.alertService.getDiagrama(id, this.data.sinalId).subscribe((res: any) => {
          if (res.code == 200) {
            //Sucesso
            this.diagramas.push(res.retorno);
            this.loading = false;
          } else {
            //Falha
            this.loading = false;
          }
        },
          (error) => {
            //Falha
            this.loading = false;
          });
      } catch (error) {
        //Falha
        this.loading = false;
      }
    });
    console.log(this.diagramas)
  }

  setPage(pag) {
    this.filterDiagram.pag = pag;
    this.getDiagramaFilter();
  }

  getTutorial() {
    this.data.id.forEach(id => {
      try {
        this.alertService.downloadPDF(id).subscribe(
          (res) => {
            this.tutoriais.push(new Uint8Array(res)); //Converte blob para Uint8Array
          },
          (error) => {
          }
        );
      } catch (error) {
      }
    });
  }

  voltarProximoSlide(array, direção) {
    console.log(array)
    if (direção) {
      //PROXIMO
      if (array.length - 1 < this.slideIndex + 1) {
        return
      }
      this.slideIndex++
    } else {
      //VOLTAR
      if (this.slideIndex - 1 < 0) {
        return
      }
      this.slideIndex--
    }
  }

  pdfController(flag) {
    if (flag) {
      if (this.pageIndex < this.numPagPdf) {
        this.pageIndex++
      }
    }
    if (!flag) {
      if (this.pageIndex > 1) {
        this.pageIndex--
      }
    }
  }

  afterLoadComplete(pdf) {
    this.numPagPdf = pdf._pdfInfo.numPages;
  }

  changeZoomPdf(e) {
    this.zoomPdf = 0.02 * e.target.value
    console.log(0.02 * e.target.value)
  }

  selectSlideIndex(i) {
    this.slideIndex = i;
  }

  visualizarDiagrama(diagrama) {
    this.dialog.close();
    sessionStorage.setItem('url', window.location.href)
    if (this.user.permissao.indexOf(diagrama.permissaoId) > -1) {
      const name = diagrama.nome.split(' ').join('-');
      this.router.navigate([`diagrama/user/desktop/` + diagrama._id + "/" + name.split('/').join('')]);
    }
    else {
      this.router.navigate([`${environment.url_version}/planos`]);
    }
  }

}

interface Data_Interface {
  id: any;
  sinalId: any;
  type: string;
}
